<template>
  <div
    @dragenter.prevent="toggleActive"
    @dragleave.prevent="toggleActive"
    @dragover.prevent
    @drop.prevent="dropFile"
    :class="{ 'active-dropzone': active }"
    class="dropzone"
  >
    <label for="dropzoneReceipt"
      ><span><img src="../assets/icons/upload.svg" alt="" /></span
      >{{ $t("inputs.selectFile") }}</label
    >
    <input
      type="file"
      id="dropzoneReceipt"
      class="dropzoneFile"
      ref="receiptInput"
      @change="selectedReceipt"
    />
    <span class="dropzone-text">{{ $t("inputs.dragFile") }}</span>
  </div>
</template>
<script>
export default {
  name: "ReceiptDropZone",
  data() {
    return {
      dropzoneFile: "",
      active: false,
    };
  },
  methods: {
    toggleActive() {
      this.active = !this.active;
    },
    dropFile(e) {
      this.dropzoneFile = e.dataTransfer.files[0];
      this.$emit("dropReceipt", this.dropzoneFile);
    },
    selectedReceipt() {
      this.dropzoneFile = this.$refs.receiptInput.files[0];
      this.$emit("selectReceipt", this.dropzoneFile);
    },
  },
};
</script>
