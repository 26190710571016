<template>
  <div class="accordion__item">
    <div class="accordion__header" @click="toggleAccordion" :class="{'_active': visible}">
      <slot name="accordion-header"></slot>
    </div>
    <transition
        name="accordion"
        @enter="start"
        @after-enter="end"
        @before-leave="start"
        @after-leave="end">
    <div class="accordion__body" v-show="visible">
      <slot name="accordion-content">
      </slot>
    </div>
    </transition>


  </div>
</template>

<script>
export default {
  inject: ["accordion"],
  data(){
    return{
      index: null
    }
  },
  methods: {
    toggleAccordion(){
      if(this.visible){
        this.accordion.active = null
      }
      else{
        this.accordion.active = this.index
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    }
  },
  computed:{
    visible(){
      return this.index == this.accordion.active
    }
  },
  created() {
    this.index = this.accordion.count++;
  }
}
</script>

<style lang="scss">
.accordion__item {
  cursor: pointer;
  position: relative;
}
.accordion__header {
  display: flex;
  justify-content: space-between;
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.35s ease, opacity 0.35s ease;
  overflow: hidden;
}

.accordion-enter-from,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>