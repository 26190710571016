<template>
  <div class="personal-account">
    <div class="personal-account__container">
      <h1 class="personal-account__title main-title _blue">
        {{ $t("basket.title") }}
      </h1>
      <div class="basket" v-if="tariffs.length">
        <div class="basket__left">
          <div class="bilets">
            <h4 class="basket__left-title blue-lg-text">
              {{ $t("basket.tickets") }}
            </h4>
            <button @click="clearBasket()" class="remove gray-text">
              {{ $t("basket.clear") }}
            </button>
          </div>
          <div
            class="basket__left__card"
            v-for="(item, index) in tariffs"
            :key="index"
          >
            <div class="basket__left__card__item">
              <div class="text">
                <h4 class="black-md-text">
                  {{ item.ticket_title[$lang] }}
                </h4>
                <p class="gray-sm-text">{{ item.adult_ticket[$lang] }}</p>
              </div>

              <div class="basket__left__card__item-second">
                <div class="basket__counter">
                  <button
                    type="button"
                    :disabled="adultTicketQuantity[index] === 0"
                    @click="
                      adultTicketQuantity[index]--, setLocaleStorage(index)
                    "
                  >
                    <span><img src="@/assets/icons/minus.svg" alt="" /></span>
                  </button>
                  <input
                    type="number"
                    readonly
                    :value="adultTicketQuantity[index]"
                    class="basket__quantity"
                  />
                  <button
                    :disabled="
                      bonusActual + item.adult_actual_price >= bonusTotal &&
                      item.is_bonus == 1
                    "
                    type="button"
                    @click="
                      adultTicketQuantity[index]++, setLocaleStorage(index)
                    "
                  >
                    <span><img src="@/assets/icons/plus.svg" alt="" /></span>
                  </button>
                </div>

                <div class="price">
                  <div class="price-flex">
                    <h4
                      class="blue-md-text"
                      :class="{ noPrice: tarifBonus[index] != null }"
                    >
                      {{ formatPrice(item.adult_actual_price) }}
                    </h4>
                    <h4 class="blue-md-text" v-if="tarifBonus[index] != null">
                      {{ formatPrice(tarifBonus[index]) }}
                    </h4>
                  </div>
                  <button
                    v-if="!item.child_actual_price"
                    @click="removeTariff(item, index)"
                    class="remove gray-text"
                  >
                    <img src="@/assets/icons/remove.svg" alt="" />
                    {{ $t("basket.delete") }}
                  </button>
                </div>
              </div>
            </div>
            <!-- <hr v-if="(!item.child_actual_price || !index + 1 === tariffs.length)" class="line line__first" /> -->
            <!-- <br v-if="item.child_actual_price" /> -->
            <div
              class="basket__left__card__item"
              v-if="item.child_actual_price"
            >
              <div class="text">
                <h4 class="black-md-text">
                  {{ item.ticket_title[$lang] }}
                </h4>
                <p class="gray-sm-text">{{ item.child_ticket[$lang] }}</p>
              </div>
              <div class="basket__left__card__item-second">
                <div class="basket__counter">
                  <button
                    type="button"
                    :disabled="childTicketQuantity[index] === 0"
                    @click="
                      childTicketQuantity[index]--, setLocaleStorageChild(index)
                    "
                  >
                    <span><img src="@/assets/icons/minus.svg" alt="" /></span>
                  </button>
                  <input
                    type="number"
                    readonly
                    :value="childTicketQuantity[index]"
                    class="basket__quantity"
                  />
                  <button
                    :disabled="
                      bonusActual + item.child_actual_price >= bonusTotal &&
                      item.is_bonus == 1
                    "
                    type="button"
                    @click="
                      childTicketQuantity[index]++, setLocaleStorageChild(index)
                    "
                  >
                    <span><img src="@/assets/icons/plus.svg" alt="" /></span>
                  </button>
                </div>

                <div class="price">
                  <div class="price-flex">
                    <h4
                      class="blue-md-text"
                      :class="{ noPrice: tarifBonusChild[index] != null }"
                    >
                      {{ formatPrice(item.child_actual_price) }}
                    </h4>
                    <h4
                      class="blue-md-text"
                      v-if="tarifBonusChild[index] != null"
                    >
                      {{ formatPrice(tarifBonusChild[index]) }}
                    </h4>
                  </div>
                  <button
                    @click="removeTariff(item, index)"
                    class="remove gray-text"
                  >
                    <img src="@/assets/icons/remove.svg" alt="" />
                    {{ $t("basket.delete") }}
                  </button>
                </div>
              </div>
            </div>
            <!-- <hr v-if="(!index + 1 === tariffs.length || item.child_actual_price && !item.child_actual_price)" class="line" /> -->
            <!-- <hr class="line" /> -->
          </div>
        </div>
        <div class="basket__right">
          <!-- <h4 class="basket__right__price blue-lg-text">Сумма к оплате</h4> -->
          <!-- <div class="pay">
            <p class="black-md-text">К оплате</p>
            <h4 class="price black-md-text" style="font-weight: 700">
              {{ formatPrice(total) }}
            </h4>
          </div> -->
          <BuyTicketComponent
            @emitDatas="emitDatas"
            :adult="adultTicketQuantity"
            :child="childTicketQuantity"
          />
          <div class="card">
            <!-- <p class="black-sm-text">Бонусы к начислению</p> -->
            <!-- <h4 class="price green-price"> -->
            <!-- {{ formatPrice(getBonus) }} -->
            <!-- 740 Б -->

            <!-- </h4> -->
          </div>
          <!-- <a>
            <button @click="toBuy">Перейти к оплате</button>
          </a> -->
        </div>
      </div>
      <div
        class="textNo"
        v-else
        @click="scrollToSection"
        v-html="$t('basket.addBefore')"
      ></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import BuyTicketComponent from "./BuyTicketComponent.vue";
export default {
  data() {
    return {
      adultTicketQuantity: [0, 0, 0],
      childTicketQuantity: [0, 0, 0],
      selectedIndex: 0,
      tariffs: [],
      basketTariffes: [],
      tarifBonus: [],
      tarifBonusChild: [],
      bonusActual: null,
      bonusTotal: null,
    };
  },
  computed: {
    ...mapState(["user", "paymentInfo", "totalAmount", "tariffId", "footer"]),
    ...mapGetters({
      isAuth: "getIsAuth",
      isUnAuth: "getUnAuth",
    }),
    // total() {
    //   if (this.tariffs[2]) {
    //     return (
    //       this.tariffs[0].adult_actual_price * this.adultTicketQuantity[0] +
    //       this.tariffs[0].child_actual_price * this.childTicketQuantity[0] +
    //       this.tariffs[1].adult_actual_price * this.adultTicketQuantity[1] +
    //       this.tariffs[1].child_actual_price * this.childTicketQuantity[1] +
    //       this.tariffs[2].adult_actual_price * this.adultTicketQuantity[2] +
    //       this.tariffs[2].child_actual_price * this.childTicketQuantity[2]
    //     );
    //   } else if (this.tariffs[1]) {
    //     return (
    //       this.tariffs[0].adult_actual_price * this.adultTicketQuantity[0] +
    //       this.tariffs[0].child_actual_price * this.childTicketQuantity[0] +
    //       this.tariffs[1].adult_actual_price * this.adultTicketQuantity[1] +
    //       this.tariffs[1].child_actual_price * this.childTicketQuantity[1]
    //     );
    //   } else if (this.tariffs[0]) {
    //     return (
    //       this.tariffs[0].adult_actual_price * this.adultTicketQuantity[0] +
    //       this.tariffs[0].child_actual_price * this.childTicketQuantity[0]
    //     );
    //   }
    // },
    getBonus() {
      return Math.floor(this.total / 100);
    },
  },
  created() {
    if (localStorage.getItem("cart_products")) {
      this.tariffs = JSON.parse(localStorage.getItem("cart_products"));
      this.tariffs.map((item, index) => {
        if (item.child_actual_price !== null) {
          this.childTicketQuantity[index] = item.quantChild || 1;
        }
        this.adultTicketQuantity[index] = item.quantStat || 1;
      });
    }
  },
  mounted() {
    this.getUserBonus();
  },
  methods: {
    ...mapActions(["fetchTariffs"]),
    ...mapMutations(["SET_PAYMENT", "SET_TOTAL", "SET_TARIFFID", "SET_RULE"]),
    hideModal(isOpen) {
      this.showModal = isOpen;
      if (this.status === "Processed") {
        this.$router.push("/personal-account");
      }
    },
    setLocaleStorage(id) {
      this.tariffs[id].quantStat = this.adultTicketQuantity[id];
      localStorage.setItem("cart_products", JSON.stringify(this.tariffs));
    },
    setLocaleStorageChild(id) {
      this.tariffs[id].quantChild = this.childTicketQuantity[id];
      localStorage.setItem("cart_products", JSON.stringify(this.tariffs));
    },
    scrollToSection() {
      this.$emit("scroll", "tariffs");
      this.$router.push("/");
    },

    emitDatas(data, bonus) {
      this.bonusActual = bonus;
      this.tarifBonus = [data[0], data[2], data[4]];
      this.tarifBonusChild = [data[1], data[3], data[5]];
    },
    clearBasket() {
      localStorage.removeItem("cart_products");
      this.$store.commit("REMOVE_CART");
      this.tariffs = [];
    },
    getUserBonus() {
      axios
        .get("V1/user-info", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          this.bonusTotal = response.data.bonus;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    openRulePage(object) {
      this.SET_RULE(object);
    },
    async removeTariff(item, index) {
      await this.$store.commit("ADD_TO_CART", { product: item, count: 1 });
      this.tariffs = JSON.parse(localStorage.getItem("cart_products"));
      this.adultTicketQuantity.splice(index, 1),
        this.childTicketQuantity.splice(index, 1),
        this.adultTicketQuantity.push(0),
        this.childTicketQuantity.push(0);
      // this.tariffs.find((tariff) => tariff.id === item.id);
    },
    formatPrice(currency) {
      return currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " ₸";
    },
    toBuy() {
      if (this.total <= 0) {
        alert("Выберите билет");
      } else {
        if (this.adultTicketQuantity[0] > 0) {
          localStorage.setItem("fromBasket", true);
          this.$router.push({
            name: "Покупка билета",
            query: {
              adultTicketQuantity: this.adultTicketQuantity[0],
              adultTicketQuantity1: this.adultTicketQuantity[1],
              adultTicketQuantity2: this.adultTicketQuantity[2],
              childTicketQuantity: this.childTicketQuantity[0],
              childTicketQuantity1: this.childTicketQuantity[1],
              childTicketQuantity2: this.childTicketQuantity[2],
            },
          });
        }
      }
    },
  },
  watch: {},
  components: { BuyTicketComponent },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}

.black-md-text {
  @include fontStyle(Nunito, normal, 600, 24px, 33px, #2c3131);
  letter-spacing: 0.02em;
  font-size: calc(16px + 8 * (100vw / 1320)) !important;
}

.black-sm-text {
  @include fontStyle(Nunito, normal, 600, 20px, 27px, #2c3131);
  letter-spacing: 0.02em;
}

.blue-lg-text {
  @include fontStyle(Nunito, normal, 700, 32px, 44px, #256ed8);
  letter-spacing: 0.02em;
  margin-bottom: 32px;

  @media (max-width: 991.9px) {
    display: none;
  }
}

.blue-md-text {
  @include fontStyle(Nunito, normal, 600, 24px, 33px, #256ed8);
  font-size: calc(16px + 8 * (100vw / 1320)) !important;
  letter-spacing: 0.02em;
  text-align: end;

  @media (max-width: 349px) {
    text-align: start;
  }
}

.gray-sm-text {
  @include fontStyle(Nunito, normal, 500, 20px, 27px, #626262);
  letter-spacing: 0.02em;
  font-size: calc(14px + 6 * (100vw / 1320)) !important;
}

.gray-text {
  @include fontStyle(Nunito, normal, 600, 20px, 27px, #a9a9a9);
  margin-top: 24px;

  @media (max-width: 991.9px) {
    margin-top: 5px;
  }
  &:hover {
    color: #898989;
  }

  font-size: calc(14px + 6 * (100vw / 1320)) !important;
}

.green-price {
  @include fontStyle(Nunito, normal, 700, 20px, 27px, #209e2f);
  letter-spacing: 0.02em;
}

.personal-account {
  margin: 100px 0 130px 0;

  @media (max-width: 991.9px) {
    margin: 32px 0 70px 0;
  }

  &__container {
    max-width: 1660px;
    margin: 0 auto;
  }
}

.line {
  width: 100%;
  border: 1px solid #e3e5e6;
  margin: 32px 0;
  // &:nth-child(-1){
  //     display: none;
  //   }
  // &__first{
  //   &:nth-child(n-1){
  //     display: none;
  //   }
  // }
}
.textNo {
  margin-top: 150px;
}
.basket {
  margin-top: 150px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  @media (max-width: 991.9px) {
    margin-top: 50px;
  }

  &__container {
    max-width: 1660px;
  }

  @media (max-width: $laptop + px) {
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }

  &__left {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 48px 32px;
    width: 68%;

    @media (max-width: 991.9px) {
      padding: 48px 16px 0 16px;
      box-shadow: unset;
    }

    @media (max-width: $laptop + px) {
      width: 100%;
    }

    &__card {
      position: relative;
      padding: 25px 0;
      display: flex;
      flex-direction: column;
      gap: 50px;
      &__item {
        display: flex;
        justify-content: space-between;
        width: 100%;
        // margin-bottom: 50px;

        @media (max-width: 991.9px) {
          flex-direction: column;
          gap: 5px;
        }

        &-second {
          display: flex;
          gap: 90px;

          @media (max-width: 991.9px) {
            justify-content: space-between;
            gap: unset;
          }

          @media (max-width: 349px) {
            flex-direction: column;
          }

          .price {
            min-width: 120px;
            max-width: 120px;
            text-align: end;

            @media (max-width: 349px) {
              text-align: start;
            }
          }
        }

        position: relative;
      }

      .text {
        width: 57%;

        @media (max-width: 991.9px) {
          width: 100%;
        }
      }

      .add {
        width: 27%;
        max-width: 247px;
        border: 1px solid #e3e5e6;
        border-radius: 15px;
        height: 56px;

        // margin: 0 96px;
        button {
          padding: 11px 27px;
        }

        .minus,
        .plus {
          font-size: 28px;
        }

        .count {
          border-left: 1px solid #e3e5e6;
          border-right: 1px solid #e3e5e6;
        }
      }

      &:after {
        position: absolute;
        content: "";
        height: 1px;
        background-color: #e3e5e6;
        width: 100%;
        bottom: 0;
      }
      &:last-child {
        .basket__left__card__item {
          &:last-child {
            margin-bottom: 0 !important;
          }
        }

        &::after {
          background-color: transparent;
        }
      }
    }
  }

  &__right {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 48px 32px;
    width: 30%;

    @media (max-width: $laptop + px) {
      width: 100%;
    }

    @media (max-width: 991.9px) {
      padding: 0 16px 0 16px;
      box-shadow: unset;
    }

    .pay,
    .card {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .card {
      margin: 12px 0 48px 0;
    }

    button {
      background: #256ed8;
      border-radius: 15px;
      width: 100%;
      padding: 18px 0;
      @include fontStyle(Nunito, normal, 700, 20px, 27px, #ffffff);
    }
  }

  &__counter {
    background: #ffffff;
    border: 1px solid #e3e5e6;
    border-radius: 15px;
    border: 1px solid #e3e5e6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;

    button {
      padding: 0 23px;
      background: rgba(0, 0, 0, 0);

      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
      }
    }
  }

  &__quantity {
    max-width: 70px;
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #256ed8;
    height: 100%;
    border-left: 1px solid #e3e5e6;
    border-right: 1px solid #e3e5e6;
  }
}
.price-flex {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  gap: 5px;
  .blue-md-text {
    white-space: nowrap;
  }
}
button:disabled,
button[disabled] {
  height: 100%;
  border-radius: 0 15px 15px 0;
  background-color: #e8e8e8;
  opacity: 0.3;
  color: #666666;
  cursor: default;
}
.bilets {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .gray-text {
    padding-top: 10px;
    margin-top: 0;
  }

  @media (max-width: 991.98px) {
    justify-content: right;
  }
}
</style>
