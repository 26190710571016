<template>
  <component :is="layout">
    <router-view />
  </component>
</template>
<script>
import MainLayout from "@/views/layouts/main";
import ReceiptLayout from "@/views/layouts/receipt";
import { mapState } from "vuex";

export default {
  name: "App",
  components: { MainLayout, ReceiptLayout },
  data: () => ({}),
  computed: {
    ...mapState(["scrollElement"]),
    layout() {
      return (this.$route.meta.layout || "main") + "-layout";
    },
  },
};
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
body,
html {
  overflow-x: hidden;
}
.content {
  flex: 1 0 auto;
}

.footer {
  flex: 0 0 auto;
}
</style>
