<template>
  <div class="select">
    <div class="select__btn" @click="isVisible = !isVisible" ref="selectBtn">
      <div class="select__text">
        <span v-if="selected.icon"><img :src="selected.icon" alt="" /></span
        >{{ selected.name }}
      </div>
      <span class="select__arrow"
        ><img src="@/assets/icons/blue-arrow.svg" alt=""
      /></span>
    </div>
    <transition name="fade">
      <ul class="select__list" v-if="isVisible" v-click-out-side="hideSelect">
        <li
          v-for="option in options"
          :key="option.value"
          @click="selectOption(option)"
        >
          <span v-if="option.icon"><img :src="option.icon" alt="" /></span
          >{{ option.name }}
        </li>
      </ul>
    </transition>
  </div>
</template>
<script>
import clickOutSide from "@mahdikhashan/vue3-click-outside";
export default {
  directives: {
    clickOutSide,
  },
  props: {
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    selected: {
      type: String,
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    selectOption(option) {
      this.$emit("select", option);
      this.isVisible = false;
    },
    hideSelect(e) {
      const selectBtn = this.$refs.selectBtn;
      if (!(e.target == selectBtn || selectBtn.contains(event.target))) {
        this.isVisible = false;
      }
    },
  },
};
</script>

<style lang="scss">
.select {
  font-weight: 500;
  font-size: 20px;
  line-height: 205%;
  letter-spacing: 0.02em;
  color: #256ed8;
  position: relative;
  @media (max-width: $tablet + px) {
    font-size: 17px;
  }
  @media (max-width: $mobileSmall + px) {
    font-size: 14px;
  }

  &__btn {
    display: flex;
    justify-content: space-between;
    height: 55px;
    background: #ffffff;
    border: 1px solid #256ed8;
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding: 0 24px 0 16px;
    @media (max-width: $mobileSmall + px) {
      height: 44px;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    span {
      display: inline-block;
      width: 30px;
      height: 30px;
      @media (max-width: $mobileSmall + px) {
        width: 20px;
        height: 20px;
      }
      margin: 0 15px 0 0;
      @media (max-width: $tablet + px) {
        margin: 0 10px 0 0;
      }
      @media (max-width: $mobileSmall + px) {
        margin: 0 5px 0 0;
      }
    }
  }

  &__arrow {
    width: 20px !important;
    height: 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 17px;
      height: 17px;
    }
    @media (max-width: $mobileSmall + px) {
      width: 15px;
      height: 15px;
      img {
        width: 13px;
        height: 13px;
      }
    }
  }

  &__list {
    position: absolute;
    width: 100%;
    padding: 0 24px 0 16px;
    border: 1px solid #256ed8;
    margin: 5px 0 0 0;
    border-radius: 5px;
    background: #ffffff;
    cursor: pointer;

    li {
      display: flex;
      align-items: center;
      padding: 7px 0;
    }

    span {
      display: inline-block;
      width: 30px;
      height: 30px;
      @media (max-width: $mobileSmall + px) {
        width: 20px;
        height: 20px;
      }
      margin: 0 15px 0 0;
      @media (max-width: $tablet + px) {
        margin: 0 10px 0 0;
      }
      @media (max-width: $mobileSmall + px) {
        margin: 0 5px 0 0;
      }
    }

    @media (max-width: $tablet + px) {
      li {
        padding: 5px 0;
      }
    }
    @media (max-width: $mobileSmall + px) {
      li {
        padding: 3px 0;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
