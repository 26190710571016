<template>
  <div class="about">
    <div class="about__container _container">
      <h1 class="about__title main-title _blue">
        {{ aboutPage.title[$lang] }}
      </h1>
      <div
        class="about__body"
        :class="{ about__vacancies: aboutPage.id === 9 }"
      >
        <swiper
          v-if="aboutPage.id != 10 && aboutPage.id != 9"
          :slidesPerView="1"
          :pagination="{ clickable: true }"
          :effect="'fade'"
          :speed="400"
          class="about__swiper"
        >
          <swiper-slide class="_ibg">
            <img
              :src="'https://admin.hawaii.kz/' + aboutPage.img"
              alt=""
            />
          </swiper-slide>
          <swiper-slide class="_ibg">
            <img
              :src="'https://admin.hawaii.kz/' + aboutPage.img_a"
              alt=""
            />
          </swiper-slide>
          <swiper-slide class="_ibg">
            <img
              :src="'https://admin.hawaii.kz/' + aboutPage.img_b"
              alt=""
            />
          </swiper-slide>
        </swiper>

        <div class="about__map responsiveMap" v-if="aboutPage.id === 10">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2906.736418124428!2d76.77425731576791!3d43.23598568732623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x388343ca8b72ddc5%3A0x778be6cf90ae6066!2z0JDQutCy0LDQv9Cw0YDQuiBIYXdhaWksIE1pYW1pIEFxdWFwYXJrJlNQQQ!5e0!3m2!1sru!2skz!4v1659956353093!5m2!1sru!2skz"
            width="600"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div class="about__content">
          <div class="about__info" v-html="aboutPage.description[$lang]"></div>
          <div class="about__contacts" v-if="aboutPage.id === 6">
            <div class="about__contact">
              <span class="about__icon">
                <img src="@/assets/icons/blue-phone.svg" alt="" />
              </span>
              312-15-11
            </div>
            <div class="about__contact">
              <span class="about__icon">
                <img src="@/assets/icons/blue-phone.svg" alt="" />
              </span>
              +7(705)-990-09-94 Эльвира
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination, EffectFade, EffectCoverflow } from "swiper";
import { mapState, mapActions } from "vuex";

SwiperCore.use([Pagination, EffectFade]);
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [EffectCoverflow, Pagination],
    };
  },
  data() {
    return {
      slides: [
        require("@/assets/images/corporative-clients/01.jpg"),
        require("@/assets/images/corporative-clients/01.jpg"),
        require("@/assets/images/corporative-clients/01.jpg"),
        require("@/assets/images/corporative-clients/01.jpg"),
      ],
      map: false,
    };
  },
  computed: {
    ...mapState(["aboutPageId", "footer"]),
    aboutPage() {
      return this.footer.about.find(
        (item) => item.slug === this.$route.params.id
      );
    },
  },
  created() {
    this.fetchFooter();
  },
  methods: {
    ...mapActions(["fetchFooter"]),
  },
};
</script>

<style lang="scss">
.about {
  padding: 100px 0 130px 0;
  @media (max-width: $mobileSmall + px) {
    padding: 32px 0;
  }
  @media (max-width: 991.98px) {
    padding: 65px 0;
  }

  &__title {
    margin: 0 0 150px 0;
    @media (max-width: $tablet + px) {
      margin: 0 0 107px 0;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 71px 0;
    }
  }

  &__body {
    @media (min-width: $tablet + px) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row-reverse;
      gap: 0 40px;
    }
  }

  &__vacancies {
    @media (min-width: $tablet + px) {
      display: block;
      max-width: 645px;
      margin: 0 auto;
      text-align: center;
    }
  }

  &__content {
    flex: 0 1 620px;
  }

  &__info {
    margin: 0 0 16px 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 138.888%;
    letter-spacing: 0.02em;
    color: #2c3131;

    p {
      text-align: justify;
      &:not(:last-child) {
        margin: 0 0 12px 0;
      }
    }

    @media (max-width: $tablet + px) {
      font-size: 16px;
      margin: 0 0 14px 0;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
      line-height: 118.75%;
      text-align: center;
      margin: 0 0 12.5px 0;
    }
  }

  &__contacts {
    @media (max-width: $mobile) {
      width: fit-content;
      margin: 0 auto;
    }
  }

  &__contact {
    font-weight: 400;
    font-size: 18px;
    line-height: 138.8888%;
    letter-spacing: 0.02em;
    color: #2c3131;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin: 0 0 16.5px 0;
    }

    @media (max-width: $tablet + px) {
      font-size: 16px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
      &:not(:last-child) {
        margin: 0 0 13px 0;
      }
    }
  }

  &__icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 0 12px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 18px;
      height: 18px;
    }

    @media (max-width: $tablet + px) {
      width: 22px;
      height: 22px;
      margin: 0 8px 0 0;
      img {
        width: 16px;
        height: 16px;
      }
    }
    @media (max-width: $mobileSmall + px) {
      width: 20px;
      height: 20px;
      margin: 0 5px 0 0;
      img {
        width: 15px;
        height: 15px;
      }
    }
  }

  &__swiper {
    width: 536px;
    height: 600px;
    margin: 0;
    @media (max-width: 1366.98px) {
      width: 436px;
      height: 500px;
    }
    @media (max-width: 991.98px) {
      margin: 0 auto;
    }
    @media (max-width: 767.98px) {
      width: 306px;
      height: 350px;
    }
    @media (max-width: 479.98px) {
      width: 206px;
      height: 256px;
    }

    .swiper-slide {
      border: 3px solid #256ed8;
      border-radius: 60px;
      height: 501px;
      overflow: hidden;
      @media (max-width: 1366.98px) {
        height: 401px;
      }
      @media (max-width: 767.98px) {
        height: 306px;
        border-radius: 15px;
        border-width: 2px;
      }
      @media (max-width: 479.98px) {
        height: 206px;
      }
    }

    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 45.83px;
      @media (max-width: 767.98px) {
        bottom: 22.92px;
      }
    }

    .swiper-pagination-bullet {
      width: 8.33px;
      height: 8.33px;
      margin: 0 12.5px !important;
      background: #b3b3bb;
      opacity: 1;

      &-active {
        background: #256ed8;
      }

      @media (max-width: 767.98px) {
        margin: 0 6.25px !important;
        width: 4.17px;
        height: 4.17px;
      }
    }
  }

  &__map {
    width: 536px;
    height: 501px;
    @media (max-width: $laptop + px) {
      width: 450px;
      height: 401px;
    }
    @media (max-width: $tablet + px) {
      margin: 0 auto 50px;
    }
    @media (max-width: 479.98px) {
      width: 100%;
      margin: 0 auto 24px;
    }
  }
}
</style>
