<template>
 <div class="success-payment">
  <p v-html="$t('thanks')" />
 </div>
</template>
<script>
export default {
 created() {
    setTimeout(() => {
      this.$router.push('/'); // переадресация на другую страницу
    }, 5000); // 5000 миллисекунд = 5 секунд
  },
}
</script>
<style lang="scss" scoped>
 .success-payment {
  width: 100%;
  height: 700px;
  background: url('../assets/images/successPayment.png') no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
   background: linear-gradient(270deg, #FF865E 0%, rgba(255, 144, 81, 0.972864) 41.32%, rgba(255, 211, 0, 0.8) 97.04%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   background-clip: text;
   font-family: 'Nunito', sans-serif;
   font-weight: 700;
   font-size: min(max(32px, calc(2rem + ((1vw - 3.2px) * 2.0536))), 55px);
   line-height: 75px;
   text-align: center;
   letter-spacing: 0.02em;
   text-transform: uppercase;
  }
 }
</style>