import { createI18n } from "vue-i18n";
import store from "./store";

export default createI18n({
  locale: `${store.getters.getLang}`,
  fallbackLocale: `${store.getters.getLang}`,
  messages: {
    kz: require("./locales/kz.json"),
    ru: require("./locales/ru.json"),
  },
});
