<template>
  <div
    @dragenter.prevent="toggleActive"
    @dragleave.prevent="toggleActive"
    @dragover.prevent
    @drop.prevent="dropFile"
    :class="{ 'active-dropzone': active }"
    class="dropzone"
  >
    <label for="dropzonePassport"
      ><span><img src="../assets/icons/upload.svg" alt="" /></span
      >{{ $t("inputs.selectFile") }}</label
    >
    <input
      type="file"
      id="dropzonePassport"
      class="dropzonePassport"
      ref="passportInput"
      @change="selectedPassport"
    />
    <span class="dropzone-text">{{ $t("inputs.dragFile") }}</span>
  </div>
</template>

<script>
export default {
  name: "PassportDropZone",
  data() {
    return {
      dropzoneFile: "",
      active: false,
    };
  },
  methods: {
    toggleActive() {
      this.active = !this.active;
    },
    dropFile(e) {
      this.dropzoneFile = e.dataTransfer.files[0];
      this.$emit("dropPassport", this.dropzoneFile);
    },
    selectedPassport() {
      this.dropzoneFile = this.$refs.passportInput.files[0];
      this.$emit("selectPassport", this.dropzoneFile);
    },
  },
};
</script>
