import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import { SetupCalendar } from "v-calendar";
import VueMask from "vue-the-mask";
import i18n from "./i18n";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

axios.defaults.baseURL = "https://admin.hawaii.kz/api/";
axios.defaults.params = {
  lang: localStorage.getItem("lang") || "ru",
};

const app = createApp(App)
  .use(store)
  .use(router)
  .use(VueMask)
  .use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 10,
    timeout: 5000,
    newestOnTop: true,
  })
  .use(i18n)
  .use(SetupCalendar, {});
app.config.globalProperties.$lang = localStorage.getItem("lang") || "ru";
app.mount("#app");
