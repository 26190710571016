<template>
  <div class="buy-ticket">
    <div class="buy-ticket__container _container">
      <div class="buy-ticket__body" v-if="selectedTariff">
        <form class="buy-ticket__form" v-if="tariffs">
          <!-- <div class="buy-ticket__group">
            <div class="buy-ticket__item">
              <label>Тариф</label>
              <select
                v-model="selectedIndex"
                class="buy-ticket__select main-input"
              >
                <option :value="0" :key="0">Выберите тариф</option>

                <option
                  v-for="tariff in tariffs"
                  :value="tariff.id"
                  :key="tariff.id"
                >
                  {{ tariff.ticket_title[$lang] }}
                </option>
              </select>
            </div>
            <div class="buy-ticket__item">
              <div class="buy-ticket__block">
                <div class="buy-ticket__info">
                  <div
                    v-if="selectedTariff.adult_ticket"
                    class="buy-ticket__type"
                  >
                    {{ selectedTariff.adult_ticket[$lang] }}
                  </div>
                  <div style="display: flex; gap: 10px">
                    <div
                      v-if="selectedTariff.adult_actual_price"
                      class="buy-ticket__price"
                      :class="{ noPrice: tarifBonus != null }"
                    >
                      {{
                        formatPrice(
                          selectedTariff.adult_actual_price * adult[0]
                        )
                      }}
                    </div>
                    <div v-if="tarifBonus != null" class="buy-ticket__price">
                      {{ formatPrice(tarifBonus) }}
                    </div>
                  </div>
                </div>
                <div class="buy-ticket__counter">
                  <button
                    type="button"
                    :disabled="adult[0] === 0"
                    @click="adult[0]--"
                  >
                    <span><img src="@/assets/icons/minus.svg" alt="" /></span>
                  </button>
                  <input
                    type="number"
                    readonly
                    :value="adult[0]"
                    class="buy-ticket__quantity"
                  />
                  <button type="button" @click="adult[0]++">
                    <span><img src="@/assets/icons/plus.svg" alt="" /></span>
                  </button>
                </div>
              </div>
              <div
                class="buy-ticket__block"
                v-if="selectedTariff.child_actual_price"
              >
                <div class="buy-ticket__info">
                  <div class="buy-ticket__type">
                    {{ selectedTariff.child_ticket[$lang] }}
                  </div>

                  <div style="display: flex; gap: 10px">
                    <div
                      class="buy-ticket__price"
                      :class="{ noPrice: tarifBonusChild != null }"
                    >
                      {{
                        formatPrice(
                          selectedTariff.child_actual_price * child[0]
                        )
                      }}
                    </div>
                    <div
                      v-if="tarifBonusChild != null"
                      class="buy-ticket__price"
                    >
                      {{ formatPrice(tarifBonusChild) }}
                    </div>
                  </div>
                </div>
                <div class="buy-ticket__counter">
                  <button
                    type="button"
                    :disabled="child[0] === 0"
                    @click="child[0]--"
                  >
                    <span><img src="@/assets/icons/minus.svg" alt="" /></span>
                  </button>
                  <input
                    type="number"
                    readonly
                    :value="child[0]"
                    class="buy-ticket__quantity"
                  />
                  <button type="button" @click="child[0]++">
                    <span><img src="@/assets/icons/plus.svg" alt="" /></span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="buy-ticket__group" v-if="showSelect1">
            <div class="buy-ticket__item">
              <label>Тариф</label>
              <select
                v-model="selectedIndex1"
                class="buy-ticket__select main-input"
              >
                <option :value="0" :key="0">Выберите тариф</option>
                <option
                  v-for="tariff in tariffs"
                  :value="tariff.id"
                  :key="tariff.id"
                >
                  {{ tariff.ticket_title[$lang] }}
                </option>
              </select>
            </div>
            <div class="buy-ticket__item">
              <div class="buy-ticket__block">
                <div class="buy-ticket__info">
                  <div
                    v-if="selectedTariff1.adult_ticket"
                    class="buy-ticket__type"
                  >
                    {{ selectedTariff1.adult_ticket[$lang] }}
                  </div>
                  <div style="display: flex; gap: 10px">
                    <div
                      v-if="selectedTariff1.adult_actual_price"
                      class="buy-ticket__price"
                      :class="{ noPrice: tarifBonus1 != null }"
                    >
                      {{
                        formatPrice(
                          selectedTariff1.adult_actual_price * adult[1]
                        )
                      }}
                    </div>
                    <div v-if="tarifBonus1 != null" class="buy-ticket__price">
                      {{ formatPrice(tarifBonus1) }}
                    </div>
                  </div>
                </div>
                <div class="buy-ticket__counter">
                  <button
                    type="button"
                    :disabled="adult[1] === 0"
                    @click="adult[1]--"
                  >
                    <span><img src="@/assets/icons/minus.svg" alt="" /></span>
                  </button>
                  <input
                    type="number"
                    readonly
                    :value="adult[1]"
                    class="buy-ticket__quantity"
                  />
                  <button type="button" @click="adult[1]++">
                    <span><img src="@/assets/icons/plus.svg" alt="" /></span>
                  </button>
                </div>
              </div>
              <div
                class="buy-ticket__block"
                v-if="selectedTariff1.child_actual_price"
              >
                <div class="buy-ticket__info">
                  <div class="buy-ticket__type">
                    {{ selectedTariff1.child_ticket[$lang] }}
                  </div>
                  <div style="display: flex; gap: 10px">
                    <div
                      class="buy-ticket__price"
                      :class="{ noPrice: tarifBonusChild1 != null }"
                    >
                      {{
                        formatPrice(
                          selectedTariff1.child_actual_price * child[1]
                        )
                      }}
                    </div>
                    <div
                      v-if="tarifBonusChild1 != null"
                      class="buy-ticket__price"
                    >
                      {{ formatPrice(tarifBonusChild1) }}
                    </div>
                  </div>
                </div>
                <div class="buy-ticket__counter">
                  <button
                    type="button"
                    :disabled="child[1] === 0"
                    @click="child[1]--"
                  >
                    <span><img src="@/assets/icons/minus.svg" alt="" /></span>
                  </button>
                  <input
                    type="number"
                    readonly
                    :value="child[1]"
                    class="buy-ticket__quantity"
                  />
                  <button type="button" @click="child[1]++">
                    <span><img src="@/assets/icons/plus.svg" alt="" /></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="buy-ticket__group" v-if="showSelect2">
            <div class="buy-ticket__item">
              <label>Тариф</label>
              <select
                v-model="selectedIndex2"
                class="buy-ticket__select main-input"
              >
                <option :value="0" :key="0">Выберите тариф</option>
                <option
                  v-for="tariff in tariffs"
                  :value="tariff.id"
                  :key="tariff.id"
                >
                  {{ tariff.ticket_title[$lang] }}
                </option>
              </select>
            </div>
            <div class="buy-ticket__item">
              <div class="buy-ticket__block">
                <div class="buy-ticket__info">
                  <div
                    v-if="selectedTariff2.adult_ticket"
                    class="buy-ticket__type"
                  >
                    {{ selectedTariff2.adult_ticket[$lang] }}
                  </div>
                  <div style="display: flex; gap: 10px">
                    <div
                      v-if="selectedTariff2.adult_actual_price"
                      class="buy-ticket__price"
                      :class="{ noPrice: tarifBonus2 != null }"
                    >
                      {{
                        formatPrice(
                          selectedTariff2.adult_actual_price * adult[2]
                        )
                      }}
                    </div>
                    <div v-if="tarifBonus2 != null" class="buy-ticket__price">
                      {{ formatPrice(tarifBonus2) }}
                    </div>
                  </div>
                </div>
                <div class="buy-ticket__counter">
                  <button
                    type="button"
                    :disabled="adult[2] === 0"
                    @click="adult[2]--"
                  >
                    <span><img src="@/assets/icons/minus.svg" alt="" /></span>
                  </button>
                  <input
                    type="number"
                    readonly
                    :value="adult[2]"
                    class="buy-ticket__quantity"
                  />
                  <button type="button" @click="adult[2]++">
                    <span><img src="@/assets/icons/plus.svg" alt="" /></span>
                  </button>
                </div>
              </div>
              <div
                class="buy-ticket__block"
                v-if="selectedTariff2.child_actual_price"
              >
                <div class="buy-ticket__info">
                  <div class="buy-ticket__type">
                    {{ selectedTariff2.child_ticket[$lang] }}
                  </div>
                  <div style="display: flex; gap: 10px">
                    <div
                      class="buy-ticket__price"
                      :class="{ noPrice: tarifBonusChild2 != null }"
                    >
                      {{
                        formatPrice(
                          selectedTariff2.child_actual_price * child[2]
                        )
                      }}
                    </div>
                    <div
                      v-if="tarifBonusChild2 != null"
                      class="buy-ticket__price"
                    >
                      {{ formatPrice(tarifBonusChild2) }}
                    </div>
                  </div>
                </div>
                <div class="buy-ticket__counter">
                  <button
                    type="button"
                    :disabled="child[2] === 0"
                    @click="child[2]--"
                  >
                    <span><img src="@/assets/icons/minus.svg" alt="" /></span>
                  </button>
                  <input
                    type="number"
                    readonly
                    :value="child[2]"
                    class="buy-ticket__quantity"
                  />
                  <button type="button" @click="child[2]++">
                    <span><img src="@/assets/icons/plus.svg" alt="" /></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="buy-ticket__item action-buttons">
            <button
              class="buy-ticket__add blue-button"
              type="button"
              v-if="!showSelect2"
              @click="addTariff"
            >
              Добавить билет
            </button>
            <button
              class="buy-ticket__remove blue-button"
              type="button"
              v-if="showSelect2 || showSelect1"
              @click="removeTariff"
            >
              Удалить билет
            </button>
          </div> -->
          <!-- <div class="buy-ticket__block1">
            <div class="buy-ticket__type1">{{ $t("basket.promocode") }}</div>
            <div class="buy-ticket__block1-promocode">
              <input type="text" :placeholder="$t('basket.placeholder_promocode')" v-model="promocode" />
              <button>{{ $t('basket.prombutton') }}</button>
            </div>
          </div> -->
          <div class="buy-ticket__block">
            <div class="buy-ticket__type">{{ $t("basket.summa") }}</div>
            <div class="buy-ticket__type">{{ formatPrice(total) }}</div>
          </div>
          <div class="buy-ticket__block bonuss" v-if="bonusBoolean">
            <div class="buy-ticket__block bonuss-flex">
              <!-- <label class="bonuss__switch">
                <input type="checkbox" v-model="bonusValue" />
                <span class="bonuss__switch-slider round"></span>
              </label> -->
              <div class="buy-ticket__info">
                <div class="buy-ticket__type bonuss-title">
                  {{ $t("basket.writtenBonus") }}
                </div>
                <div class="bonuss-value">
                  {{ formatPrice(bonusTotal) }} {{ $t("basket.bonuses") }}
                </div>
              </div>
            </div>
            <div class="buy-ticket__type bonuss-minus" v-if="bonusValue">
              - {{ formatPrice(bonusTotals) }}
            </div>
          </div>
          <div class="buy-ticket__block" v-if="bonusBoolean">
            <div class="buy-ticket__type">{{ $t("basket.toPay") }}</div>
            <div class="buy-ticket__type">
              {{ formatPrice(buytotal) }}
            </div>
          </div>
          <div class="buy-ticket__item buy-ticket__payment" v-if="buytotal > 0">
            <label>{{ $t("basket.paymentType") }}</label>
            <SelectComponent
              class="buy-ticket__dropdown"
              :selected="selectedPayment"
              :options="options"
              @select="selectOption"
            >
            </SelectComponent>
          </div>
          <div class="buy-ticket__item checkbox-item" v-if="selectedPayment.id">
            <input
              type="checkbox"
              v-model="checked"
              class="checkbox-item__input"
              id="remember"
            />
            <span
              @click="checked = !checked"
              for="remember"
              class="checkbox-item__icon"
            ></span>
            <label for="remember" v-for="rule in footer.rules" :key="rule.id"
              >{{ $t("ozn1") }}
              <router-link
                to="/rule/payment"
                class="checkbox-item__text"
                @click="openRulePage(rule)"
              >
                {{ $t("oznText") }} </router-link
              >{{ $t("ozn2") }}
            </label>
          </div>
          <div class="buy-ticket__item">
            <button
              class="buy-ticket__button blue-button"
              @click.prevent="buyTicket"
              v-if="buytotal > 0"
            >
              {{ $t("basket.pay") }} {{ formatPrice(buytotal) }}
            </button>
            <button
              class="buy-ticket__button blue-button"
              @click.prevent="buyTicketBonus"
              v-else
            >
              {{ $t("basket.pay") }} {{ formatPrice(buytotal) }}
            </button>
          </div>
        </form>
        <ModalComponent :showModal="showModal" @hideModal="hideModal">
          <div class="ticket-modal">
            <img
              class="ticket-modal__qr"
              :src="`https://admin.hawaii.kz/api/V1/qr-code/${kaspiInfo.qr_token}`"
              alt=""
            />

            <h2 class="ticket-modal__text">{{ $t("vam") }}</h2>
          </div>
        </ModalComponent>
      </div>
    </div>
    <transition name="notification">
      <div
        class="buy-ticket__notification notification"
        v-if="showNotification"
        :class="{ active: showNotification }"
      >
        <span class="notification__icon" @click="showNotification = false">
          <img src="../assets/icons/x.svg" alt="" />
        </span>
        <div class="notification__content" v-html="$t('basket.message')"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import SelectComponent from "@/components/SelectComponent";
import ModalComponent from "@/components/ModalComponent";
import { toRef } from "vue";
import { useToast } from "vue-toastification";

export default {
  components: { ModalComponent, SelectComponent },
  setup(props) {
    const toast = useToast();
    const adultNew = toRef(props, "adult");
    return { adultNew, toast };
  },
  data() {
    return {
      kaspiInfo: {},
      kaspiShop: {},
      bonusValue: true,
      selectedIndex: 0,
      selectedIndex1: 0,
      selectedIndex2: 0,
      showSelect1: false,
      tarifBonus: null,
      tarifBonus1: null,
      tarifBonus2: null,
      tarifBonusChild: null,
      tarifBonusChild1: null,
      tarifBonusChild2: null,
      showSelect2: false,
      bonusTotal: 32000,
      bonusTotals: 0,
      newAdult: this.adult,
      newChild: this.child,
      checked: false,
      tariffs: [],
      bonusPay: [],
      status: "",
      options: [
        {
          id: 1,
          name: "Kaspi QR",
          value: "kaspi",
          icon: require("@/assets/icons/payment/kaspi.svg"),
        },
        {
          id: 2,
          name: this.$t("basket.typeCard"),
          value: "halyk",
          icon: require("@/assets/icons/payment/card.svg"),
        },
        {
          id: 3,
          name: "Kaspi",
          value: "mobile",
          icon: require("@/assets/icons/payment/kaspi.svg"),
        },
      ],
      selectedPayment: {
        name: this.$t("basket.changeType"),
      },
      qr: null,
      showModal: false,
      showNotification: false,
    };
  },
  watch: {
    newAdult: {
      handler() {
        this.checkBonus();
        this.getTicketList();
      },
      deep: true,
    },
    newChild: {
      handler() {
        this.checkBonus();
        this.getTicketList();
      },
      deep: true,
    },
    showModal: function () {
      if (this.showModal) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },

    showSelect() {
      this.checkBonus();
    },
    showSelect1() {
      this.checkBonus();
    },
    showSelect2() {
      this.checkBonus();
    },
    selectedIndex() {
      this.checkBonus();
    },
    selectedIndex1() {
      this.checkBonus();
    },
    selectedIndex2() {
      this.checkBonus();
    },
    bonusValue() {
      this.checkBonus();
    },
  },
  computed: {
    ...mapState([
      "user",
      "paymentInfo",
      "totalAmount",
      "tariffId",
      "footer",
      "getUser",
    ]),
    ...mapGetters({
      isAuth: "getIsAuth",
      isUnAuth: "getUnAuth",
    }),

    bonusBoolean() {
      return (this.selectedTariff.is_bonus === 1 ||
        this.selectedTariff1.is_bonus === 1 ||
        this.selectedTariff2.is_bonus === 1) &&
        (this.selectedTariff.adult_actual_price <= this.bonusTotal ||
          this.selectedTariff1.adult_actual_price <= this.bonusTotal ||
          this.selectedTariff2.adult_actual_price <= this.bonusTotal)
        ? true
        : false;
    },

    selectedTariff() {
      return this.tariffs.find((tariff) => tariff.id === this.selectedIndex);
    },

    selectedTariff1() {
      if (this.showSelect1) {
        return this.tariffs.find((tariff) => tariff.id === this.selectedIndex1);
      } else {
        return { id: "" };
      }
    },
    selectedTariff2() {
      if (this.showSelect2) {
        return this.tariffs.find((tariff) => tariff.id === this.selectedIndex2);
      } else {
        return { id: "" };
      }
    },
    buytotal() {
      this.emitDatas();
      return this.total - this.bonusTotals;
    },
    total() {
      if (this.showSelect2) {
        return (
          this.selectedTariff.adult_actual_price * this.adult[0] +
          this.selectedTariff.child_actual_price * this.child[0] +
          this.selectedTariff1.adult_actual_price * this.adult[1] +
          this.selectedTariff1.child_actual_price * this.child[1] +
          this.selectedTariff2.adult_actual_price * this.adult[2] +
          this.selectedTariff2.child_actual_price * this.child[2]
        );
      } else if (this.showSelect1) {
        return (
          this.selectedTariff.adult_actual_price * this.adult[0] +
          this.selectedTariff.child_actual_price * this.child[0] +
          this.selectedTariff1.adult_actual_price * this.adult[1] +
          this.selectedTariff1.child_actual_price * this.child[1]
        );
      } else {
        return (
          this.selectedTariff.adult_actual_price * this.adult[0] +
          this.selectedTariff.child_actual_price * this.child[0]
        );
      }
    },
  },
  props: ["adult", "child"],
  created() {
    this.getTicketList();
    if (!this.isAuth) {
      this.$router.push("/");
    }
    setTimeout(() => {
      this.showNotification = true;
    }, 4000);
    this.getUserBonus();
  },
  methods: {
    ...mapActions(["fetchTariffs", "fetchTickets", "requestUser"]),
    ...mapMutations(["SET_PAYMENT", "SET_TOTAL", "SET_TARIFFID", "SET_RULE"]),
    hideModal(isOpen) {
      this.showModal = isOpen;
      if (this.status === "Processed") {
        this.$router.push("/thanks");
      }
    },
    emitDatas() {
      this.$emit(
        "emitDatas",
        [
          this.tarifBonus,
          this.tarifBonusChild,
          this.tarifBonus1,
          this.tarifBonusChild1,
          this.tarifBonus2,
          this.tarifBonusChild2,
        ],
        this.bonusTotals
      );
    },
    async buyTicketBonus() {
      await axios
        .get("V1/bonus-payment", {
          params: {
            bank: this.selectedPayment.value,
            bonus_amount: this.total,
            amount: this.buytotal,
            ticket_id_1: this.selectedTariff.id,
            adult_num_1: this.adult[0],
            child_num_1: this.child[0],
            bonus_num_1: this.tarifBonus == 0 ? true : false,
            bonus_child_num_1: this.tarifBonusChild == 0 ? true : false,
            ticket_id_2: this.selectedTariff1.id,
            adult_num_2: this.adult[1],
            child_num_2: this.child[1],
            bonus_num_2: this.tarifBonus1 == 0 ? true : false,
            bonus_child_num_2: this.tarifBonusChild1 == 0 ? true : false,
            ticket_id_3: this.selectedTariff2.id,
            adult_num_3: this.adult[2],
            child_num_3: this.child[2],
            bonus_num_3: this.tarifBonus2 == 0 ? true : false,
            bonus_child_num_3: this.tarifBonusChild2 == 0 ? true : false,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then(() => {
          // this.toast.success(`Успешно оплачено с бонусами!`);
          localStorage.removeItem("cart_products");
          this.$store.commit("REMOVE_CART");
          this.fetchTickets();
          this.requestUser();
          this.$router.push("/thanks");
        });
    },
    checkBonus() {
      (this.tarifBonus = null),
        (this.tarifBonus1 = null),
        (this.tarifBonus2 = null),
        (this.tarifBonusChild = null),
        (this.tarifBonusChild1 = null),
        (this.tarifBonusChild2 = null);
      if (this.bonusValue === false) {
        this.bonusTotals = 0;
      } else {
        this.bonusTotals = 0;
        var currentBonusTotal = this.bonusTotal;
        var allPrice;
        var more;
        if (
          this.selectedTariff.is_bonus === 1 &&
          this.selectedTariff.adult_actual_price <= currentBonusTotal &&
          this.adult[0] != 0
        ) {
          if (
            this.selectedTariff.adult_actual_price * this.adult[0] <
            currentBonusTotal
          ) {
            allPrice = this.selectedTariff.adult_actual_price * this.adult[0];
          } else {
            allPrice =
              this.selectedTariff.adult_actual_price *
              Math.floor(
                currentBonusTotal / this.selectedTariff.adult_actual_price
              );
          }
          currentBonusTotal -= allPrice;
          this.bonusTotals += allPrice;
          more = this.selectedTariff.adult_actual_price * this.adult[0];
          this.tarifBonus = more - allPrice;
        }
        if (
          this.selectedTariff.is_bonus === 1 &&
          this.selectedTariff.child_actual_price <= currentBonusTotal &&
          this.child[0] != 0
        ) {
          if (
            this.selectedTariff.child_actual_price * this.child[0] <
            currentBonusTotal
          ) {
            allPrice = this.selectedTariff.child_actual_price * this.child[0];
          } else {
            allPrice =
              this.selectedTariff.child_actual_price *
              Math.floor(
                currentBonusTotal / this.selectedTariff.child_actual_price
              );
          }
          currentBonusTotal -= allPrice;
          this.bonusTotals += allPrice;
          more = this.selectedTariff.child_actual_price * this.child[0];
          this.tarifBonusChild = more - allPrice;
        }
        if (
          this.selectedTariff1.is_bonus === 1 &&
          this.selectedTariff1.adult_actual_price <= currentBonusTotal &&
          this.adult[1] != 0
        ) {
          if (
            this.selectedTariff1.adult_actual_price * this.adult[1] <
            currentBonusTotal
          ) {
            allPrice = this.selectedTariff1.adult_actual_price * this.adult[1];
          } else {
            allPrice =
              this.selectedTariff1.adult_actual_price *
              Math.floor(
                currentBonusTotal / this.selectedTariff1.adult_actual_price
              );
          }
          currentBonusTotal -= allPrice;
          this.bonusTotals += allPrice;
          more = this.selectedTariff1.adult_actual_price * this.adult[1];
          this.tarifBonus1 = more - allPrice;
        }
        if (
          this.selectedTariff1.is_bonus === 1 &&
          this.selectedTariff1.child_actual_price <= currentBonusTotal &&
          this.child[1] != 0
        ) {
          if (
            this.selectedTariff1.child_actual_price * this.child[1] <
            currentBonusTotal
          ) {
            allPrice = this.selectedTariff1.child_actual_price * this.child[1];
          } else {
            allPrice =
              this.selectedTariff1.child_actual_price *
              Math.floor(
                currentBonusTotal / this.selectedTariff1.child_actual_price
              );
          }
          currentBonusTotal -= allPrice;
          this.bonusTotals += allPrice;
          more = this.selectedTariff1.child_actual_price * this.child[1];
          this.tarifBonusChild1 = more - allPrice;
        }
        if (
          this.selectedTariff2.is_bonus === 1 &&
          this.selectedTariff2.adult_actual_price <= currentBonusTotal &&
          this.adult[2] != 0
        ) {
          if (
            this.selectedTariff2.adult_actual_price * this.adult[2] <
            currentBonusTotal
          ) {
            allPrice = this.selectedTariff2.adult_actual_price * this.adult[2];
          } else {
            allPrice =
              this.selectedTariff2.adult_actual_price *
              Math.floor(
                currentBonusTotal / this.selectedTariff2.adult_actual_price
              );
          }
          currentBonusTotal -= allPrice;
          this.bonusTotals += allPrice;
          more = this.selectedTariff2.adult_actual_price * this.adult[2];
          this.tarifBonus2 = more - allPrice;
        }
        if (
          this.selectedTariff2.is_bonus === 1 &&
          this.selectedTariff2.child_actual_price <= currentBonusTotal &&
          this.child[2] != 0
        ) {
          if (
            this.selectedTariff2.child_actual_price * this.child[2] <
            currentBonusTotal
          ) {
            allPrice = this.selectedTariff2.child_actual_price * this.child[2];
          } else {
            allPrice =
              this.selectedTariff2.child_actual_price *
              Math.floor(
                currentBonusTotal / this.selectedTariff2.child_actual_price
              );
          }
          currentBonusTotal -= allPrice;
          this.bonusTotals += allPrice;
          more = this.selectedTariff2.child_actual_price * this.child[2];
          this.tarifBonusChild2 = more - allPrice;
        }
        // return (this.bonusTotals = 7000);
      }
    },
    getTicketList() {
      axios
        .get(`V1/ticket-list`)
        .then((response) => {
          this.tariffs = response.data.data;
          // this.tariffs = this.tariffs.filter((tariff) => tariff.status === "1");
          let tariffAction = JSON.parse(localStorage.getItem("cart_products"));
          if (localStorage.getItem("payment") === "true") {
            this.selectedIndex = this.tariffId;
            localStorage.removeItem("payment");
          } else if (tariffAction !== null || tariffAction?.length > 0) {
            tariffAction.filter((i) => {
              for (let x = 0; x <= 3; x++) {
                let ids = tariffAction[Object.keys(tariffAction)[x]];
                if (ids && this.adult) {
                  if (x === 0) {
                    this.selectedIndex = ids.id;
                    // console.log('1', x, ids);
                  } else if (x === 1) {
                    this.selectedIndex1 = ids.id;
                    this.showSelect1 = true;
                    // console.log('2', x, ids);
                  } else if (x === 2) {
                    // console.log('3', x, ids);
                    this.selectedIndex2 = ids.id;
                    this.showSelect2 = true;
                  }
                } else if (!this.adult) {
                  this.selectedIndex = this.tariffs[0].id;
                  this.selectedIndex1 = this.tariffs[0].id;
                  this.selectedIndex2 = this.tariffs[0].id;
                }
              }
            });
          } else {
            this.selectedIndex = this.tariffs[0].id;
          }
        })

        .catch((error) => {
          console.log(error);
        });
    },
    openRulePage(object) {
      this.SET_RULE(object);
    },
    addTariff() {
      if (this.showSelect1) {
        this.showSelect2 = true;
      } else {
        this.showSelect1 = true;
      }
    },
    removeTariff() {
      if (this.showSelect2) {
        this.showSelect2 = false;
      } else {
        this.showSelect1 = false;
      }
    },
    checkStatus(kaspi) {
      let startTime = new Date().getTime();
      const interval = setInterval(() => {
        if (new Date().getTime() - startTime < kaspi.confirm_time * 1000) {
          axios
            .get("V1/statusqr", {
              params: {
                QrPaymentId: kaspi.invoice_id,
              },
            })
            .then((response) => {
              this.status = response.data.Data.Status;
              if (response.data.Data.Status == "Processed") {
                clearInterval(interval);
                this.hideModal(false);
                // this.toast.success(this.$t("successPayBefore"));
                localStorage.removeItem("cart_products");
                this.$store.commit("REMOVE_CART");
                this.fetchTickets();
                this.requestUser();
                this.$router.push("/thanks");
              }
            })

            .catch((error) => {
              // this.errorMessage = error.message;
              console.error("There was an error!", error);
            });
        } else {
          clearInterval(interval);
          return;
        }
      }, kaspi.refresh_time * 1000);
    },
    getUserBonus() {
      axios
        .get("V1/user-info", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          this.bonusTotal = response.data.bonus;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    buyTicket() {
      if (this.buytotal == 0) {
        alert("Добавьте, пожалуйста, билет");
      } else {
        if (this.selectedPayment.id != null) {
          if (this.checked) {
            axios
              .get("V1/payment", {
                params: {
                  bank: this.selectedPayment.value,
                  // amount: this.buytotal,
                  ticket_id_1: this.selectedTariff.id,
                  adult_num_1: this.adult[0],
                  child_num_1: this.child[0],
                  ticket_id_2: this.selectedTariff1.id,
                  adult_num_2: this.adult[1],
                  child_num_2: this.child[1],
                  ticket_id_3: this.selectedTariff2.id,
                  adult_num_3: this.adult[2],
                  child_num_3: this.child[2],
                },

                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "access_token"
                  )}`,
                },
              })
              .then((response) => {
                if (this.bonusTotals > 0) {
                  axios
                    .get("V1/bonus-payment", {
                      params: {
                        bank: this.selectedPayment.value,
                        bonus_amount: this.total,
                        amount: this.buytotal,
                        ticket_id_1: this.selectedTariff.id,
                        adult_num_1: this.adult[0],
                        child_num_1: this.child[0],
                        bonus_num_1: this.tarifBonus == 0 ? true : false,
                        bonus_child_num_1:
                          this.tarifBonusChild == 0 ? true : false,
                        ticket_id_2: this.selectedTariff1.id,
                        adult_num_2: this.adult[1],
                        child_num_2: this.child[1],
                        bonus_num_2: this.tarifBonus1 == 0 ? true : false,
                        bonus_child_num_2:
                          this.tarifBonusChild1 == 0 ? true : false,
                        ticket_id_3: this.selectedTariff2.id,
                        adult_num_3: this.adult[2],
                        child_num_3: this.child[2],
                        bonus_num_3: this.tarifBonus2 == 0 ? true : false,
                        bonus_child_num_3:
                          this.tarifBonusChild2 == 0 ? true : false,
                      },
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "access_token"
                        )}`,
                      },
                    })
                    .then(() => {
                      console.log("Успешно");
                    })
                    .catch(() => {
                      console.log("Не успешно");
                    });
                }
                if (this.selectedPayment.id == 2) {
                  this.SET_PAYMENT(response.data);
                  this.SET_TOTAL(this.buytotal);

                  var halyk;
                  var totalPrice = this.buytotal;
                  var payment = this.paymentInfo;

                  (function (halyk) {
                    var isTest = true;

                    var testConfig = {
                      pageUrL: "https://epay.homebank.kz/payform/",
                      origin: "https://epay.homebank.kz",
                      TokenAPIConfig: {
                        url: "https://oauth.homebank.kz/epay2/oauth2/token",
                        clientId: "test",
                      },
                    };

                    var prodConfig = {
                      pageUrL: "https://epay.homebank.kz/payform/",
                      origin: "https://epay.homebank.kz",
                      TokenAPIConfig: {
                        url: "https://epay-oauth.homebank.kz/oauth2/token",
                        clientId: "uberflower",
                      },
                    };

                    halyk.Config = function Config() {
                      if (isTest) return testConfig;
                      else return prodConfig;
                    };

                    var pageUrl = halyk.Config().pageUrL;

                    var paymentPageOrigin = halyk.Config().origin;

                    function pay(params) {
                      location.href =
                        pageUrl +
                        "?params=" +
                        LZString.compressToEncodedURIComponent(
                          encodeParams(params)
                        );
                    }

                    var paymentWidgedCallBack = undefined;
                    var widgetNode = undefined;
                    var LZString = (function () {
                      function o(o, r) {
                        if (!t[o]) {
                          t[o] = {};
                          for (var n = 0; n < o.length; n++) {
                            t[o][o.charAt(n)] = n;
                          }
                        }
                        return t[o][r];
                      }

                      var r = String.fromCharCode,
                        n =
                          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
                        e =
                          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+-$",
                        t = {},
                        i = {
                          compressToBase64: function (o) {
                            if (null == o) {
                              return "";
                            }
                            var r = i._compress(o, 6, function (o) {
                              return n.charAt(o);
                            });
                            switch (r.length % 4) {
                              default:
                              case 0:
                                return r;
                              case 1:
                                return r + "===";
                              case 2:
                                return r + "==";
                              case 3:
                                return r + "=";
                            }
                          },
                          decompressFromBase64: function (r) {
                            return null == r
                              ? ""
                              : "" == r
                              ? null
                              : i._decompress(r.length, 32, function (e) {
                                  return o(n, r.charAt(e));
                                });
                          },
                          compressToUTF16: function (o) {
                            return null == o
                              ? ""
                              : i._compress(o, 15, function (o) {
                                  return r(o + 32);
                                }) + " ";
                          },
                          decompressFromUTF16: function (o) {
                            return null == o
                              ? ""
                              : "" == o
                              ? null
                              : i._decompress(o.length, 16384, function (r) {
                                  return o.charCodeAt(r) - 32;
                                });
                          },
                          compressToUint8Array: function (o) {
                            for (
                              var r = i.compress(o),
                                n = new Uint8Array(2 * r.length),
                                e = 0,
                                t = r.length;
                              t > e;
                              e++
                            ) {
                              var s = r.charCodeAt(e);
                              (n[2 * e] = s >>> 8), (n[2 * e + 1] = s % 256);
                            }
                            return n;
                          },
                          decompressFromUint8Array: function (o) {
                            if (null === o || void 0 === o) {
                              return i.decompress(o);
                            }
                            for (
                              var n = new Array(o.length / 2),
                                e = 0,
                                t = n.length;
                              t > e;
                              e++
                            ) {
                              n[e] = 256 * o[2 * e] + o[2 * e + 1];
                            }
                            var s = [];
                            return (
                              n.forEach(function (o) {
                                s.push(r(o));
                              }),
                              i.decompress(s.join(""))
                            );
                          },
                          compressToEncodedURIComponent: function (o) {
                            return null == o
                              ? ""
                              : i._compress(o, 6, function (o) {
                                  return e.charAt(o);
                                });
                          },
                          decompressFromEncodedURIComponent: function (r) {
                            return null == r
                              ? ""
                              : "" == r
                              ? null
                              : ((r = r.replace(/ /g, "+")),
                                i._decompress(r.length, 32, function (n) {
                                  return o(e, r.charAt(n));
                                }));
                          },
                          compress: function (o) {
                            return i._compress(o, 16, function (o) {
                              return r(o);
                            });
                          },
                          _compress: function (o, r, n) {
                            if (null == o) {
                              return "";
                            }
                            var e,
                              t,
                              i,
                              s = {},
                              p = {},
                              u = "",
                              c = "",
                              a = "",
                              l = 2,
                              f = 3,
                              h = 2,
                              d = [],
                              m = 0,
                              v = 0;
                            for (i = 0; i < o.length; i += 1) {
                              if (
                                ((u = o.charAt(i)),
                                Object.prototype.hasOwnProperty.call(s, u) ||
                                  ((s[u] = f++), (p[u] = !0)),
                                (c = a + u),
                                Object.prototype.hasOwnProperty.call(s, c))
                              ) {
                                a = c;
                              } else {
                                if (
                                  Object.prototype.hasOwnProperty.call(p, a)
                                ) {
                                  if (a.charCodeAt(0) < 256) {
                                    for (e = 0; h > e; e++) {
                                      (m <<= 1),
                                        v == r - 1
                                          ? ((v = 0), d.push(n(m)), (m = 0))
                                          : v++;
                                    }
                                    for (
                                      t = a.charCodeAt(0), e = 0;
                                      8 > e;
                                      e++
                                    ) {
                                      (m = (m << 1) | (1 & t)),
                                        v == r - 1
                                          ? ((v = 0), d.push(n(m)), (m = 0))
                                          : v++,
                                        (t >>= 1);
                                    }
                                  } else {
                                    for (t = 1, e = 0; h > e; e++) {
                                      (m = (m << 1) | t),
                                        v == r - 1
                                          ? ((v = 0), d.push(n(m)), (m = 0))
                                          : v++,
                                        (t = 0);
                                    }
                                    for (
                                      t = a.charCodeAt(0), e = 0;
                                      16 > e;
                                      e++
                                    ) {
                                      (m = (m << 1) | (1 & t)),
                                        v == r - 1
                                          ? ((v = 0), d.push(n(m)), (m = 0))
                                          : v++,
                                        (t >>= 1);
                                    }
                                  }
                                  l--,
                                    0 == l && ((l = Math.pow(2, h)), h++),
                                    delete p[a];
                                } else {
                                  for (t = s[a], e = 0; h > e; e++) {
                                    (m = (m << 1) | (1 & t)),
                                      v == r - 1
                                        ? ((v = 0), d.push(n(m)), (m = 0))
                                        : v++,
                                      (t >>= 1);
                                  }
                                }
                                l--,
                                  0 == l && ((l = Math.pow(2, h)), h++),
                                  (s[c] = f++),
                                  (a = String(u));
                              }
                            }
                            if ("" !== a) {
                              if (Object.prototype.hasOwnProperty.call(p, a)) {
                                if (a.charCodeAt(0) < 256) {
                                  for (e = 0; h > e; e++) {
                                    (m <<= 1),
                                      v == r - 1
                                        ? ((v = 0), d.push(n(m)), (m = 0))
                                        : v++;
                                  }
                                  for (t = a.charCodeAt(0), e = 0; 8 > e; e++) {
                                    (m = (m << 1) | (1 & t)),
                                      v == r - 1
                                        ? ((v = 0), d.push(n(m)), (m = 0))
                                        : v++,
                                      (t >>= 1);
                                  }
                                } else {
                                  for (t = 1, e = 0; h > e; e++) {
                                    (m = (m << 1) | t),
                                      v == r - 1
                                        ? ((v = 0), d.push(n(m)), (m = 0))
                                        : v++,
                                      (t = 0);
                                  }
                                  for (
                                    t = a.charCodeAt(0), e = 0;
                                    16 > e;
                                    e++
                                  ) {
                                    (m = (m << 1) | (1 & t)),
                                      v == r - 1
                                        ? ((v = 0), d.push(n(m)), (m = 0))
                                        : v++,
                                      (t >>= 1);
                                  }
                                }
                                l--,
                                  0 == l && ((l = Math.pow(2, h)), h++),
                                  delete p[a];
                              } else {
                                for (t = s[a], e = 0; h > e; e++) {
                                  (m = (m << 1) | (1 & t)),
                                    v == r - 1
                                      ? ((v = 0), d.push(n(m)), (m = 0))
                                      : v++,
                                    (t >>= 1);
                                }
                              }
                              l--, 0 == l && ((l = Math.pow(2, h)), h++);
                            }
                            for (t = 2, e = 0; h > e; e++) {
                              (m = (m << 1) | (1 & t)),
                                v == r - 1
                                  ? ((v = 0), d.push(n(m)), (m = 0))
                                  : v++,
                                (t >>= 1);
                            }
                            for (;;) {
                              if (((m <<= 1), v == r - 1)) {
                                d.push(n(m));
                                break;
                              }
                              v++;
                            }
                            return d.join("");
                          },
                          decompress: function (o) {
                            return null == o
                              ? ""
                              : "" == o
                              ? null
                              : i._decompress(o.length, 32768, function (r) {
                                  return o.charCodeAt(r);
                                });
                          },
                          _decompress: function (o, n, e) {
                            var t,
                              i,
                              s,
                              p,
                              u,
                              c,
                              a,
                              l,
                              f = [],
                              h = 4,
                              d = 4,
                              m = 3,
                              v = "",
                              w = [],
                              A = { val: e(0), position: n, index: 1 };
                            for (i = 0; 3 > i; i += 1) {
                              f[i] = i;
                            }
                            for (p = 0, c = Math.pow(2, 2), a = 1; a != c; ) {
                              (u = A.val & A.position),
                                (A.position >>= 1),
                                0 == A.position &&
                                  ((A.position = n), (A.val = e(A.index++))),
                                (p |= (u > 0 ? 1 : 0) * a),
                                (a <<= 1);
                            }
                            switch ((t = p)) {
                              case 0:
                                for (
                                  p = 0, c = Math.pow(2, 8), a = 1;
                                  a != c;

                                ) {
                                  (u = A.val & A.position),
                                    (A.position >>= 1),
                                    0 == A.position &&
                                      ((A.position = n),
                                      (A.val = e(A.index++))),
                                    (p |= (u > 0 ? 1 : 0) * a),
                                    (a <<= 1);
                                }
                                l = r(p);
                                break;
                              case 1:
                                for (
                                  p = 0, c = Math.pow(2, 16), a = 1;
                                  a != c;

                                ) {
                                  (u = A.val & A.position),
                                    (A.position >>= 1),
                                    0 == A.position &&
                                      ((A.position = n),
                                      (A.val = e(A.index++))),
                                    (p |= (u > 0 ? 1 : 0) * a),
                                    (a <<= 1);
                                }
                                l = r(p);
                                break;
                              case 2:
                                return "";
                            }
                            for (f[3] = l, s = l, w.push(l); ; ) {
                              if (A.index > o) {
                                return "";
                              }
                              for (p = 0, c = Math.pow(2, m), a = 1; a != c; ) {
                                (u = A.val & A.position),
                                  (A.position >>= 1),
                                  0 == A.position &&
                                    ((A.position = n), (A.val = e(A.index++))),
                                  (p |= (u > 0 ? 1 : 0) * a),
                                  (a <<= 1);
                              }
                              switch ((l = p)) {
                                case 0:
                                  for (
                                    p = 0, c = Math.pow(2, 8), a = 1;
                                    a != c;

                                  ) {
                                    (u = A.val & A.position),
                                      (A.position >>= 1),
                                      0 == A.position &&
                                        ((A.position = n),
                                        (A.val = e(A.index++))),
                                      (p |= (u > 0 ? 1 : 0) * a),
                                      (a <<= 1);
                                  }
                                  (f[d++] = r(p)), (l = d - 1), h--;
                                  break;
                                case 1:
                                  for (
                                    p = 0, c = Math.pow(2, 16), a = 1;
                                    a != c;

                                  ) {
                                    (u = A.val & A.position),
                                      (A.position >>= 1),
                                      0 == A.position &&
                                        ((A.position = n),
                                        (A.val = e(A.index++))),
                                      (p |= (u > 0 ? 1 : 0) * a),
                                      (a <<= 1);
                                  }
                                  (f[d++] = r(p)), (l = d - 1), h--;
                                  break;
                                case 2:
                                  return w.join("");
                              }
                              if (
                                (0 == h && ((h = Math.pow(2, m)), m++), f[l])
                              ) {
                                v = f[l];
                              } else {
                                if (l !== d) {
                                  return null;
                                }
                                v = s + s.charAt(0);
                              }
                              w.push(v),
                                (f[d++] = s + v.charAt(0)),
                                h--,
                                (s = v),
                                0 == h && ((h = Math.pow(2, m)), m++);
                            }
                          },
                        };
                      return i;
                    })();

                    // "function" == typeof define && define.amd ? define(function () {
                    //   return LZString;
                    // }) : "undefined" != typeof module && null != module && (module.exports = LZString);

                    function encodeParams(params) {
                      if (params === undefined || params === null) {
                        return "";
                      }

                      if (typeof params !== "object") {
                        return "" + params;
                      }

                      var result = [];

                      for (var name in params) {
                        if (name) {
                          result.push(
                            name +
                              "=" +
                              encodeURIComponent(encodeParams(params[name]))
                          );
                        }
                      }

                      return result.join("&");
                    }

                    function addCssClass() {
                      var style = document.createElement("style");
                      style.type = "text/css";
                      var styleClasses =
                        ".widgetScreen {position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 1000; background-color: rgba(5, 5, 5, 0.5); display: flex; justify-content: center; align-items: center;}";
                      styleClasses +=
                        ".iframeBox{border-radius: 4px; position: relative; width: 400px; z-index: 1010; background-color: #fff; -ms-overflow-style: none; scrollbar-width: none;}";
                      styleClasses += `.iframeHolder::-webkit-scrollbar {display: none;}`;
                      styleClasses += ".iframeBoxHeader{padding: 0px;}";
                      styleClasses +=
                        ".iframeBoxHeaderCloseButton{display:none!important}";
                      styleClasses +=
                        ".iframeBoxHeaderCloseButtonText{font-size: 10px; font-family: sans-serif; font-weight: bold; color: #fff; padding-top: 2px;}";
                      styleClasses +=
                        ".iframeBoxHeaderLabel{height:30px; text-align: center; float: left;}";
                      styleClasses +=
                        ".iframeClass{ width: 100%; height: 90vh; border: none; }";
                      //styleClasses += ".iframeHolder{ width: 100%; height: 100%; }";
                      style.innerHTML = styleClasses;
                      document
                        .getElementsByTagName("head")[0]
                        .appendChild(style);
                    }

                    function onCloseDialog(result) {
                      let modal = document.querySelectorAll(".iframeBox");
                      let modalWrapper =
                        document.querySelectorAll(".widgetScreen");

                      modalWrapper.forEach((item) => {
                        item.style.position = "relative";
                      });

                      modal.forEach((item) => {
                        item.style.display = "none";
                      });

                      if (result) {
                        localStorage.removeItem("cart_products");
                        localStorage.setItem("success_payment", true);
                        window.location.pathname = "/personal-account";
                      }

                      // paymentWidgedCallBack({ success: result });

                      // document
                      //   .getElementsByTagName("body")[0]
                      //   .removeChild(widgetNode);
                      // widgetNode = undefined;
                    }

                    function onCommandRecieved(evnt) {
                      if (evnt.origin.indexOf(paymentPageOrigin) === 0) {
                        const resultObject = JSON.parse(evnt.data);
                        console.log(resultObject);

                        onCloseDialog(
                          resultObject.success === true,
                          resultObject
                        );
                      }
                    }

                    function showPaymentWidget(params, callBack) {
                      paymentWidgedCallBack = callBack;
                      if (!widgetNode) {
                        addCssClass();
                        widgetNode = document.createElement("DIV");
                        widgetNode.className = "widgetScreen";
                        var iframeBox = document.createElement("DIV");
                        var iframeBoxHeader = document.createElement("DIV");
                        var iframeBoxLabel = document.createElement("DIV");
                        var iframeBoxCloseButton =
                          document.createElement("DIV");
                        iframeBoxLabel.className = "iframeBoxHeaderLabel";
                        iframeBoxCloseButton.className =
                          "iframeBoxHeaderCloseButton";
                        iframeBoxLabel.innerHTML = "";
                        var iframeBoxHeaderCloseButtonText =
                          document.createElement("DIV");
                        iframeBoxHeaderCloseButtonText.innerHTML = "x";

                        iframeBoxHeaderCloseButtonText.className =
                          "iframeBoxHeaderCloseButtonText";
                        iframeBoxCloseButton.appendChild(
                          iframeBoxHeaderCloseButtonText
                        );
                        iframeBoxCloseButton.addEventListener(
                          "click",
                          function () {
                            onCloseDialog(false);
                          }
                        );
                        iframeBoxHeader.appendChild(iframeBoxLabel);
                        iframeBoxHeader.appendChild(iframeBoxCloseButton);
                        iframeBoxHeader.className = "iframeBoxHeader";
                        iframeBox.className = "iframeBox";
                        var iframe = document.createElement("IFRAME");
                        var iframeHolder = document.createElement("DIV");
                        iframeHolder.className = "iframeHolder";
                        iframeHolder.appendChild(iframe);
                        //iframeBox.appendChild(iframeBoxHeader);
                        iframeBox.appendChild(iframeHolder);
                        iframe.src =
                          halyk.Config().pageUrL +
                          "?params=" +
                          LZString.compressToEncodedURIComponent(
                            encodeParams(params)
                          ) +
                          "&isShortForm=true";
                        iframe.className = "iframeClass";
                        window.addEventListener(
                          "message",
                          onCommandRecieved,
                          false
                        );
                        widgetNode.appendChild(iframeBox);
                        document
                          .getElementsByTagName("body")[0]
                          .appendChild(widgetNode);
                      }
                    }

                    function p2p(params) {
                      location.href =
                        pageUrl +
                        "?params=" +
                        LZString.compressToEncodedURIComponent(
                          encodeParams(params)
                        ) +
                        "&isTransfer=true";
                    }

                    function aft(params) {
                      location.href =
                        pageUrl +
                        "?params=" +
                        LZString.compressToEncodedURIComponent(
                          encodeParams(params)
                        ) +
                        "&isAFT=true";
                    }

                    halyk.p2p = p2p;
                    halyk.aft = aft;

                    halyk.pay = pay;
                    halyk.showPaymentWidget = showPaymentWidget;
                  })(halyk || (halyk = {}));

                  var createPaymentObject = function (
                    auth,
                    invoiceId,
                    amountTotal
                  ) {
                    var paymentObject = {
                      invoiceId: invoiceId,
                      backLink: "https://hawaii.kz/personal-account",
                      failureBackLink: `https://hawaii.kz/basket`,
                      // failureBackLink: `https://admin.hawaii.kz/api/V1/failure/${invoiceId}`,
                      postLink:
                        "https://admin.hawaii.kz/api/V1/success-halyk",
                      language: "RU",
                      description: "Оплата в интернет магазине",
                      accountId: "",
                      terminal: "b8343723-35f4-41ba-981f-07a7d279a1f5",
                      amount: amountTotal,
                      currency: "KZT",
                      cardSave: true, //Параметр должен передаваться как Boolean
                    };
                    paymentObject.auth = auth;
                    return paymentObject;
                  };

                  var token = {
                    access_token: this.paymentInfo.access_token,
                    expires_in: "1200",
                    refresh_token: "",
                    scope: "payment",
                    token_type: "Bearer",
                  };

                  halyk.showPaymentWidget(
                    createPaymentObject(
                      token,
                      this.paymentInfo.invoice_id,
                      this.buytotal
                    ),
                    { token, success: true }
                  );
                } else if (this.selectedPayment.id == 1) {
                  this.kaspiInfo = response.data;
                  axios.get(`qr-code/${this.kaspiInfo.qr_token}`, {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                        "access_token"
                      )}`,
                    },
                  });
                  this.showModal = true;
                  window.scroll(0, 0);
                  setTimeout(() => {
                    this.showModal = false;
                  }, this.kaspiInfo.expire_time * 1000);
                  this.checkStatus(this.kaspiInfo);
                } else if (this.selectedPayment.id == 4) {
                  this.bonusPay = response.data.data;
                  axios.get("V1/bonus-payment", {
                    params: {
                      bank: this.selectedPayment.value,
                      amount: this.buytotal,
                      ticket_id_1: this.selectedTariff.id,
                      adult_num_1: this.adult[0],
                      child_num_1: this.child[0],
                      ticket_id_2: this.selectedTariff1.id,
                      adult_num_2: this.adult[1],
                      child_num_2: this.child[1],
                      ticket_id_3: this.selectedTariff2.id,
                      adult_num_3: this.adult[2],
                      child_num_3: this.child[2],
                    },
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                        "access_token"
                      )}`,
                    },
                  });
                  if (this.buytotal > this.bonusTotal) {
                    this.toast.warning("У вас не хватает бонусов для оплаты!");
                  } else {
                    this.buytotal - this.bonusTotal;
                  }
                } else {
                  this.kaspiShop = response.data;
                  window.location.href = this.kaspiShop.payment_link;
                  // this.toast.success(
                  //   `Успешно оплачено, билеты в личном кабинете появится через 15 минут!`
                  // );
                  // localStorage.removeItem("cart_products");
                  this.checkStatus(this.kaspiShop);
                  this.$router.push("/thanks");
                }
              });
          } else {
            alert(this.$t("login.paymentRules"));
          }
        } else {
          alert(this.$t("basket.changeType"));
        }
      }
    },

    selectOption(option) {
      this.selectedPayment = option;
    },
    formatPrice(currency) {
      return currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " ₸";
    },
  },
};
</script>

<style lang="scss">
.buy-ticket {
  @media (max-width: 991.98px) {
    padding: 41px 0;
  }

  @media (max-width: $mobileSmall + px) {
    padding: 32px 0;
  }

  &__title {
    margin: 0 0 100px 0;

    @media (max-width: $tablet + px) {
      margin: 0 0 55px 0;
    }

    @media (max-width: $mobileSmall + px) {
      margin: 0 0 71px 0;
    }
  }

  &__form {
    @media (min-width: $tablet + px) {
    }
  }

  &__group {
    margin: 0 0 30px 0;
  }

  &__item {
    &:not(:last-child) {
      margin: 0 0 30px 0;

      @media (max-width: $tablet + px) {
        margin: 0 0 27px 0;
      }

      @media (max-width: $mobileSmall + px) {
        margin: 0 0 24px 0;
      }
    }
  }

  &__remove {
    background: none !important;
    color: #256ed8 !important;
    border: 1px solid #256ed8 !important;
  }

  &__block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &:not(:last-child) {
      margin: 0 0 25px 0;

      @media (max-width: $tablet + px) {
        font-size: 16px;
        margin: 0 0 20px 0;
      }

      @media (max-width: $mobileSmall + px) {
        font-size: 14px;
        margin: 0 0 16px 0;
      }
    }
  }

  &__block1 {
    font-size: 13px;
    &-promocode {
    margin: 10px 0 24px 0;
      display: flex;
      input {
        flex-grow: 1;
        border: 1px solid #E0E0E0;
        padding: 13px 15px;
        border-radius: 15px 0px 0px 15px;
      }
      button {
        padding: 13px 15px;
        color: white;
        background: #256ED8;
        border-radius: 0px 15px 15px 0px;
      }
    }
  }

  &__dropdown {
    .select__list {
      li {
        &:last-child {
          @media (min-width: $mobile + px) {
            display: none;
          }
        }

        &:first-child {
          @media (max-width: $mobile + px) {
            display: none;
          }
        }
      }
    }
  }

  &__select {
    width: 100%;
    display: block;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("@/assets/icons/blue-arrow.svg");
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;

    option {
      background-image: url("@/assets/icons/payment/kaspi.svg");
    }
  }

  &__type,
  label {
    font-weight: 700;
    font-size: 22px;
    letter-spacing: 0.02em;
    color: #256ed8;

    @media (max-width: $tablet + px) {
      font-size: 19px;
      margin: 0 0 0 10px;
    }

    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
      margin: 0 0 0 8px;
    }
  }

  &__type1 {
    font-size: 22px;
    color: #256ed8;
  }

  label {
    display: inline-block;
    margin: 0 0 20px 0;

    @media (max-width: $tablet + px) {
      margin: 0 0 14px 0;
    }

    @media (max-width: $mobileSmall + px) {
      margin: 0 0 8px 0;
    }
  }

  &__type {
    margin: 0 0 12px 0;

    @media (max-width: $tablet + px) {
      margin: 0 0 8px 0;
    }

    @media (max-width: $mobileSmall + px) {
      margin: 0 0 4px 0;
    }
  }

  &__price {
    font-weight: 600;
    font-size: 22px;
    line-height: 137.5%;
    letter-spacing: 0.02em;
    color: #256ed8;

    @media (max-width: $tablet + px) {
      font-size: 19px;
    }

    @media (max-width: $mobileSmall + px) {
      font-size: 15px;
    }
  }

  &__counter {
    background: #ffffff;
    border: 1px solid #256ed8;
    border-radius: 15px;
    border: 1px solid #256ed8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;

    @media (max-width: $tablet + px) {
      height: 40px;
    }

    @media (max-width: $mobileSmall + px) {
      height: 30px;
    }

    button {
      padding: 0 23px;
      background: rgba(0, 0, 0, 0);

      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
      }

      @media (max-width: $tablet + px) {
        padding: 0 17px;

        span {
          height: 22px;
          width: 22px;

          img {
            width: 13.33px;
            height: 13.33px;
          }
        }
      }

      @media (max-width: $mobileSmall + px) {
        padding: 0 10px;

        span {
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  &__quantity {
    max-width: 150px;
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #256ed8;
    height: 100%;
    border-left: 1px solid #256ed8;
    border-right: 1px solid #256ed8;

    @media (max-width: $tablet + px) {
      font-size: 19px;
    }

    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
      max-width: 86px;
    }
  }

  &__payment {
    .select {
      &__list,
      &__text {
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;

          img {
            width: 27px;
            height: 24px;
          }

          @media (max-width: $mobileSmall + px) {
            width: 20px;
            height: 20px;

            img {
              width: 18px;
              height: 19px;
            }
          }
        }
      }
    }
  }

  .checkbox-item {
    display: flex;
    align-items: center;

    &__input {
      position: absolute;
      opacity: 0;
      z-index: 1;
      width: 24px;
      height: 24px;

      @media (max-width: $tablet + px) {
        width: 22px;
        height: 22px;
      }

      @media (max-width: $mobileSmall + px) {
        width: 20px;
        height: 20px;
      }

      &:checked ~ .checkbox-item__icon {
        &:after {
          opacity: 1;
        }
      }
    }

    &__icon {
      background: #ffffff;
      border: 1px solid #256ed8;
      border-radius: 4px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      @media (max-width: $tablet + px) {
        width: 22px;
        height: 22px;
      }

      @media (max-width: $mobileSmall + px) {
        width: 20px;
        height: 20px;
      }

      &:after {
        content: url("@/assets/icons/form/checkmark.svg");
        transition: opacity 0.25s ease 0s;
        opacity: 0;
      }
    }

    label {
      display: inline-block;
      font-weight: 400;
      font-size: 20px;
      letter-spacing: 0.02em;
      color: #256ed8;
      margin: 0 0 0 12px;

      @media (max-width: $tablet + px) {
        font-size: 17px;
        margin: 0 0 0 12px;
      }

      @media (max-width: $mobileSmall + px) {
        font-size: 14px;
        margin: 0 0 0 8px;
      }
    }

    &__text {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.ticket-modal {
  padding: 60px 0;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__qr {
    width: 250px;
    height: 250px;
  }

  &__image {
    display: inline-block;
    width: 70px;
    height: 70px;
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-35px, -60px);

    img {
      width: 70px;
      height: 70px;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50px;
    right: 50px;
  }

  a {
    text-align: center;
    margin: 0 auto;
  }

  &__text {
    font-weight: 400;
    font-size: 20px;
    line-height: 165%;
    letter-spacing: 0.02em;
    color: #2c3131;
    margin: 15px 0 0 0;

    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
      margin: 10px 0 0 0;
    }
  }
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 0 20px;
}

.notification {
  position: absolute;
  top: 200px;
  right: 30px;
  max-width: 400px;
  padding: 20px 20px;
  transition: all 0.5s ease 0s;
  background: #ffffff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  border-radius: 15px;
  z-index: 3;
  opacity: 1;

  &__icon {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;

    img {
      width: 15px;
      height: 15px;
    }
  }

  &__content {
    line-height: 120%;

    span {
      color: red;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

.notification-enter-active,
.notification-leave-active {
  transition: opacity 0.5s ease;
}

.notification-enter-from,
.notification-leave-to {
  opacity: 0;
}

.bonuss {
  align-items: center;
  &-flex {
    margin: 0 !important;
    gap: 22px !important;
    align-items: center;
  }
  &-title {
    font-weight: 600;
    font-size: 20px;
  }
  &-value {
    color: #626262;
    font-weight: 600;
    font-size: 14px;
  }
  &-minus {
    color: #209e2f;
  }
  &__switch {
    position: relative;
    display: inline-block;
    width: 57px;
    margin: 0 !important;
    height: 33px;
    &-slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      &:before {
        position: absolute;
        content: "";
        height: 29px;
        width: 29px;
        left: 2px;
        top: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    input:checked + &-slider {
      background-color: #35c658;
    }

    input:focus + &-slider {
      box-shadow: 0 0 1px #35c658;
    }

    input:checked + &-slider:before {
      -webkit-transform: translateX(24px);
      -ms-transform: translateX(24px);
      transform: translateX(24px);
    }
    #appt {
      width: 84px;
      padding: 12px;
    }
    input[type="time"]::-webkit-calendar-picker-indicator {
      background: none;
      display: none;
    }

    /* Rounded sliders */
    &-slider.round {
      border-radius: 24px;
    }

    &-slider.round:before {
      border-radius: 50%;
    }
  }
  @media (max-width: $tablet + px) {
    &__switch {
      width: 42px;
      height: 26px;
      &-slider {
        &:before {
          height: 21px;
          width: 21px;
        }
      }
      input:checked + &-slider:before {
        -webkit-transform: translateX(17px);
        -ms-transform: translateX(17px);
        transform: translateX(17px);
      }
    }
    &-flex {
      gap: 14px !important;
      align-items: center;
    }
    &-title {
      font-weight: 600;
      font-size: 16px;
    }
    &-value {
      color: #626262;
      font-weight: 600;
      font-size: 14px;
    }
    &-minus {
      color: #209e2f;
    }
  }

  @media (max-width: $mobileSmall + px) {
    &-title {
      font-size: 14px;
    }
    &-value {
      font-size: 12px;
    }
  }
}
.noPrice {
  position: relative;
  color: #7f94a1 !important;
  &:before {
    border-bottom: 3px solid red;
    position: absolute;
    content: "";
    width: 100%;
    height: 50%;
    transform: rotate(-12deg);
  }
}
</style>
