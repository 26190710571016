<template>
  <div class="ticket">
    <div class="ticket__container _container">
      <h1 class="ticket__title main-title _blue">Ваш билет</h1>
      <div class="ticket__body">
        <div class="ticket__qr _ibg">
          <img :src="'https://admin.hawaii.kz/' + ticket.qr" alt="" />
        </div>
        <div class="ticket__name">
          <span class="ticket__code">{{
            ticket.order.ticket_title + " " + ticket.age
          }}</span>
        </div>
        <span class="ticket__text">Код билета: </span>
        <span class="ticket__code">{{ ticket.ticket }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      ticket: {},
    };
  },
  computed: {
    ...mapState(["ticketId", "tickets"]),
  },
  created() {
    this.tickets.forEach((ticket) => {
      this.ticket = ticket.find((ticketItem) => ticketItem.id == this.ticketId);
    });
  },
};
</script>

<style lang="scss">
.ticket {
  padding: 50px 0 70px 0;
  @media (max-width: 991.98px) {
    padding: 41px 0;
  }
  @media (max-width: $mobileSmall + px) {
    padding: 32px 0;
  }

  &__title {
    margin: 0 0 100px 0;
    @media (max-width: $tablet + px) {
      margin: 0 0 55px 0;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 71px 0;
    }
  }
  &__body {
    max-width: 250px;
    @media (max-width: $tablet + px) {
      font-size: 16px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
    }
  }

  &__qr {
    width: 250px;
    height: 250px;
    margin: 0 0 30px 0;
    @media (max-width: $mobileSmall + px) {
      width: 180px;
      height: 180px;
    }
  }
  &__name {
    margin: 0 0 20px 0;
  }
}
</style>
